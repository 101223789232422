import Service from "../Service";
const resource = "HmnHoursReport/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    SendMail(filter, requestID) {
        return Service.post(resource + "SendMail", filter, {
            params: { requestID: requestID },
        });
    },
};