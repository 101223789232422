<template lang="">
    <v-card>
        <!-- <v-btn @click="SendMail()">Prueba</v-btn> -->
        <s-crud
            :config="config"
            title="Registro de Horas"
            :filter="filter"
            noSearch
            remove
            height="auto"
            ref="scrudHoursReport"
            group-by="HrtDocument"
        >
            <template v-slot:options>
                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn
                        v-on="on"
                        text
                        black 
                        @click="dialog = true"
                        small
                        >
                            <v-icon>mdi-plus-circle-multiple-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Registro Masivo</span>
                </v-tooltip>
                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn
                        v-on="on"
                        text
                        black 
                        @click="dialogSendMailFun()"
                        small
                        >
                            <v-icon>mdi-receipt-text-send-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Enviar Correo</span>
                </v-tooltip>
            </template>
            <template v-slot:filter>
                <v-container>
                    <v-row style="margin-bottom: 5px;">
                        <v-col cols="12" md="3">
                            <s-text
                            label="Nombre o DNI"
                            v-model="filter.HrtDocument"
                            placeholder="Ingrese Nombre o DNI"
                            ></s-text>
                        </v-col>
                        <v-col cols="12" md="3">
                            <s-date
                                v-model="filter.HrtBeginDate"
                                label="Fecha Desde"
                            ></s-date>
                        </v-col>
                        <v-col cols="12" md="3">
                            <s-date
                                v-model="filter.HrtEndDate"
                                label="Fecha Hasta"
                            ></s-date>
                        </v-col>
                        <v-col cols="12" md="3">
                            <s-select-area
                                label="Area"
                                v-model="filter.AreID"
                            ></s-select-area>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>
        <v-dialog v-if="dialog" v-model="dialog" persistent max-width="1024px">
            <v-container>
                <v-row >
                    <v-col >
                    <s-toolbar   
                        no-border 
                        dark 
                        label="Reporte de Horas - Carga Masiva"
                        color="#2955c8"
                        close @close="dialog = false"
                    >

                    </s-toolbar>
                        
                        <v-card >
                            <v-col>
                                <v-row>
                                <v-col >
                                    <s-import-excel
                                        :headers = "headers"
                                        @loaded	="saveMassive($event)"
                                    ></s-import-excel>
                                </v-col>
                                </v-row>
                            </v-col>
                        </v-card>				
                    </v-col>
                </v-row>
            </v-container>
        </v-dialog>
        <v-dialog v-model="dialogSendMail" persistent max-width="300">
            <v-card>
                <s-toolbar label="Data para enviar Correo" dark color="#8e8f91" close @close="dialogSendMail = false"></s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-text autofocus style="display: inline-block;" v-model="dataHoursReport.Affair" label="Mes y Año" placeholder="Enero 2025"></s-text></h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <s-date v-model="dataHoursReport.BeginDate" label="Fecha Desde"></s-date>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <s-date v-model="dataHoursReport.EndDate" label="Fecha Hasta"></s-date>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="primary" @click="dialogDataMail()">Siguiente</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogPreviewMail" persistent max-width="800">
            <v-card>
                <s-toolbar label="Vista Previa" dark color="#8e8f91" close @close="dialogPreviewMail = false">
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-btn text small style="margin-right:5px;" v-on="on" @click="returnDialog()"> <v-icon style="font-size:18px;">
                                mdi-arrow-left</v-icon></v-btn>
                        </template>
                        <span>Regresar</span>
                    </v-tooltip>
                </s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <div class="email-container">
                                <div class="email-header">
                                    <div style="display: flex; align-items: center; gap: 10px">
                                        <h1 class="email-title">Reporte de Horas - {{ dataHoursReport.Affair}}</h1>
                                        <span class="email-label">
                                            Recibidos
                                            <span class="close-icon">×</span>
                                        </span>
                                    </div>
                                    <div class="email-actions">
                                        <v-icon style="cursor: pointer">mdi-printer-outline</v-icon>
                                        <v-icon style="cursor: pointer">mdi-open-in-new</v-icon>
                                    </div>
                                </div>
                                
                                <div class="sender-info">
                                    <div class="profile-image">
                                        <img src="/static/logo_sp.png" width="160"></img>
                                    </div>
                                    <div class="sender-details">
                                        <div class="sender-email">notificaciones@sunshineperu.com</div>
                                        <div class="recipient">
                                            para mí
                                            <v-icon>mdi-menu-down</v-icon>
                                        </div>
                                    </div>
                                    <div class="email-timestamp">
                                        <v-icon style="cursor: pointer">mdi-attachment</v-icon>
                                        <span>{{timeAgo}} (hace un momento)</span>
                                    </div>
                                    <div class="email-options">
                                        <v-icon style="transform: translateY(-2px)">mdi-star-outline</v-icon>
                                        <i style="color: #5f6368; transform: translateY(-2px)" class="far fa-smile"></i>
                                        <v-icon style="transform: translateY(-2px)">mdi-arrow-left-top</v-icon>
                                        <v-icon style="transform: translateY(-2px)">mdi-dots-vertical</v-icon>
                                    </div>
                                </div>
                                
                                <div class="email-content">
                                    <div class="email-body">
                                        <p>Estimado (a)</p>
                                        <p>Mediante el presente, se adjunta reporte de horas del {{ $fun.formatDateView(dataHoursReport.BeginDate)}} al {{ $fun.formatDateView(dataHoursReport.EndDate)}}</p>
                                        <p>Cualquier Observación, comunicarse mediante correo : <a href="mailto:Asistente.planillas@sunshineperu.com">Asistente.planillas@sunshineperu.com</a> o <a href="mailto:aux.planillas@sunshineperu.com">aux.planillas@sunshineperu.com</a></p>
                                    </div>
                                    
                                    <div class="email-divider"></div>
                                    
                                    <div class="attachment-section-container">
                                        <div class="attachment-section">
                                            <div class="attachment-label">Un archivo adjunto •</div>
                                            <div class="attachment-info">
                                                <span>Analizado por Gmail</span>
                                                <v-icon>mdi-information-outline</v-icon>
                                            </div>
                                        </div>
                                        <v-icon style="cursor: pointer">mdi-google-drive</v-icon>
                                    </div>
                                    
                                    <div class="attachment-container" style="position: relative;">
                                        <div class="attachment-preview">
                                            <img src="https://img.icons8.com/color/96/000000/microsoft-excel-2019.png" alt="Excel Preview" style="width: 100%; height: 100%; object-fit: contain;">
                                        </div>
                                        <div class="attachment-details">
                                            <img style="width: 18px; height: 18px;" src="https://img.icons8.com/color/24/000000/microsoft-excel-2019.png" alt="Excel Icon">
                                            <span class="attachment-name">ReporteHoras.xlsx</span>
                                        </div>
                                        <div class="corner-triangle"></div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="success" @click="SendMail()"><v-icon>mdi-gmail</v-icon>&nbsp;Enviar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        <AlertSunshine :dialog="dialogLoading" :totalItems="totalItems" :countItems="countItems"/>
    </v-card>
</template>

<script>

import SText from '../../components/Utils/SText.vue';
import HoursReport from '../../services/HumanResource/HoursReport';
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";

export default {
    components: {
        SText,
        sToolbarPerson
    },
    data() {
        return {
            totalItems: 0,
            countItems: 0,
            dialogLoading: false,
            dialog: false,
            filter: {
                HrtBeginDate: this.$fun.getDate(),
                HrtEndDate: this.$fun.getDate(),
                AreID: "",
                HrtDocument: "",
                EmailBody : null,
                Affair : null,
            },
            config: {
                service: HoursReport,
                model: {
                    HrtID: "ID",
                    HrtDate: "date",
                    HrtDateEntry: "date",
                    HrtDateExit: "date",
                },
                headers: [
                    { text: "ID", value: "HrtID" },
                    { text: "Semana", value: "HrtWeek" },
                    { text: "Fecha", value: "HrtDate" },
                    { text: "Dia", value: "HrtDay" },
                    { text: "DNI", value: "HrtDocument" },
                    { text: "Nombre Trabajador", value: "HrtWorker" },
                    { text: "Area", value: "HrtArea" },
                    { text: "Estado", value: "HrtStatus" },
                    { text: "Fecha Entrada", value: "HrtDateEntry" },
                    { text: "Horario Entrada", value: "HrtEntryHours" },
                    { text: "Marcación Entrada", value: "HrtEntryDialing" },
                    { text: "Fecha Salida", value: "HrtDateExit" },
                    { text: "Horario Salida", value: "HrtExitHours" },
                    { text: "Marcación Salida", value: "HrtExitDialing" },
                    { text: "Horas Bruto", value: "HrtGrossHours" },
                    { text: "Refrigerio", value: "HrtRefreshment" },
                    { text: "Horas Total", value: "HrtTotalHours" },
                    { text: "Observación", value: "HrtObservation" }
                ]
            },
            headerDetail:[
                { text: "TIPO DOC.", value: "TIPO_DOCUMENTO", width: 60 },
                { text: "DOCUMENTO", value: "DOCUMENTO", width: 60 },
                { text: "APELLIDO PATERNO", value: "APELLIDO_PATERNO", width: 60 },
                { text: "APELLIDO MATERNO", value: "APELLIDO_MATERNO", width: 60 },
                { text: "NOMBRE", value: "NOMBRE", width: 60 },
                { text: "FECHA NACIMIENTO", value: "FECHA_NACIMIENTO", width: 60 },
                { text: "GENERO", value: "GENERO", width: 60 },
            ],
            headers: [
                {
                    column	: 	"SEMANA",
                    value	: 	"SEMANA",
                    text 	: 	"SEMANA"
                },
                {
                    column	: 	"FECHA",
                    value	: 	"FECHA",
                    text 	: 	"FECHA",
                    date	: 	true
                },	
                {
                    column	: 	"DIA",
                    value	: 	"DIA",
                    text 	: 	"DIA"
                },
                {
                    column	: 	"DNI",
                    value	: 	"DNI",
                    text 	: 	"DNI"
                },	
                {
                    column	: 	"TRABAJADOR",
                    value	: 	"TRABAJADOR",
                    text 	: 	"TRABAJADOR"
                },		
                {
                    column	: 	"AREA",
                    value	: 	"AREA",
                    text 	: 	"AREA",
                },	
                {
                    column	: 	"ESTADO",
                    value	: 	"ESTADO",
                    text 	: 	"ESTADO"
                },
                {
                    column	: 	"HORARIO",
                    value	: 	"HORARIO",
                    text 	: 	"HORARIO"
                },
                {
                    column	: 	"FECHA_ENTRADA",
                    value	: 	"FECHA_ENTRADA",
                    text 	: 	"FECHA ENTRADA",
                    date	: 	true
                },
                {
                    column	: 	"HORARIO_ENTRADA",
                    value	: 	"HORARIO_ENTRADA",
                    text 	: 	"HORARIO ENTRADA",
                    time    : 	true
                },
                {
                    column	: 	"MARCACION_ENTRADA",
                    value	: 	"MARCACION_ENTRADA",
                    text 	: 	"MARCACION ENTRADA",
                    time    : 	true
                },
                {
                    column	: 	"FECHA_SALIDA",
                    value	: 	"FECHA_SALIDA",
                    text 	: 	"FECHA SALIDA",
                    date	: 	true
                },
                {
                    column	: 	"HORARIO_SALIDA",
                    value	: 	"HORARIO_SALIDA",
                    text 	: 	"HORARIO SALIDA",
                    time    : 	true
                },
                {
                    column	: 	"MARCACION_SALIDA",
                    value	: 	"MARCACION_SALIDA",
                    text 	: 	"MARCACION SALIDA",
                    time    : 	true
                },
                {
                    column	: 	"HORAS_BRUTO",
                    value	: 	"HORAS_BRUTO",
                    text 	: 	"HORAS BRUTO",
                    time    : 	true
                },
                {
                    column	: 	"REFRIGERIO",
                    value	: 	"REFRIGERIO",
                    text 	: 	"REFRIGERIO",
                    time    : 	true
                },
                {
                    column	: 	"HORAS_TOTAL",
                    value	: 	"HORAS_TOTAL",
                    text 	: 	"HORAS TOTAL",
                    time    : 	true
                },
                {
                    column	: 	"OBSERVACION",
                    value	: 	"OBSERVACION",
                    text 	: 	"OBSERVACION"
                }
            ],
            dataHoursReport:{
                BeginDate : this.$fun.getDate(""),
                EndDate : this.$fun.getDate(""),
                Affair : null,
            },
            dialogSendMail : false,
            dialogPreviewMail : false,
            timeAgo : null,
        }
    },
    methods: {
        async saveMassive(items) {
            this.dialogLoading = true;
            this.countItems = 0;
            this.totalItems = items.length;

            for (const item of items) {
                const data = {
                    HrtWeek: item.SEMANA,
                    HrtDate: item.FECHA,
                    HrtDay: item.DIA,
                    HrtDocument: item.DNI,
                    HrtWorker: item.TRABAJADOR,
                    HrtArea: item.AREA,
                    HrtStatus: item.ESTADO,
                    HrtSchedule: item.HORARIO,
                    HrtDateEntry: item.FECHA_ENTRADA,
                    HrtEntryHours: item.HORARIO_ENTRADA,
                    HrtEntryDialing: item.MARCACION_ENTRADA,
                    HrtDateExit: item.FECHA_SALIDA,
                    HrtExitHours: item.HORARIO_SALIDA,
                    HrtExitDialing: item.MARCACION_SALIDA,
                    HrtGrossHours: item.HORAS_BRUTO,
                    HrtRefreshment: item.REFRIGERIO,
                    HrtTotalHours: item.HORAS_TOTAL,
                    HrtObservation: item.OBSERVACION,
                    UsrCreateID: this.$fun.getUserID()
                };

                try {
                    const response = await HoursReport.save(data, this.$fun.getUserID());
                    this.countItems++;
                    // console.log(`Registro ${this.countItems} de ${this.totalItems} guardado correctamente.`);
                } catch (error) {
                    console.error(`Error al registrar persona (${this.countItems + 1}/${this.totalItems})`, item, error);
                }
            }

            this.totalItems = 0;
            this.countItems = 0;
            this.dialogLoading = false;
            this.dialog = false;
            this.$refs.scrudHoursReport.refresh();
            this.$fun.alert("Guardado Correctamente", "success");
        },
        SendMail(){
            this.$fun.alertFull("Esta de seguro de enviar los correos?", "question")
                .then(res => {
                    if (res.value) {
                        this.dialogLoading = true;
                        HoursReport.SendMail(this.filter,this.$fun.getUserID())
                        .then(r => {
                            console.log("r", r);
                            this.dialogLoading = false;
                            this.dialogPreviewMail = false;
                            this.dataHoursReport = {
                                BeginDate : this.$fun.getDate(""),
                                EndDate : this.$fun.getDate(""),
                                Affair : null,
                            }
                            this.$fun.alert("Enviado Correctamente", "success");
                        })
                        .catch(error => {
                            console.error("Error al enviar:", error);
                            this.dialogLoading = false;
                            this.$fun.alert("Error al enviar el correo", "error");
                        });
                    }
                });
        },
        dialogDataMail(){
            if(!this.dataHoursReport.BeginDate || !this.dataHoursReport.EndDate || !this.dataHoursReport.Affair){
                this.$fun.alert("Por favor ingrese todos los campos requeridos", "warning");
                return;
            }
            this.timeAgo = new Date().toLocaleTimeString("es-PE", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            });
            let dataHoursReportCopy = {...this.dataHoursReport};
            this.dialogSendMail = false;
            this.dialogPreviewMail = true;
            dataHoursReportCopy = {
                EmailBody: `<html><body><p>Estimado (a)</p><p>Mediante el presente, se adjunta reporte de horas del ${this.$fun.formatDateView(this.dataHoursReport.BeginDate)} al ${this.$fun.formatDateView(this.dataHoursReport.EndDate)}.</p><p>Cualquier Observación, comunicarse mediante correo : Asistente.planillas@sunshineperu.com o aux.planillas@sunshineperu.com</p></body></html>`,
                Affair: `Reporte de Horas - ${this.dataHoursReport.Affair}`,
            }
            this.filter.EmailBody = dataHoursReportCopy.EmailBody;
            this.filter.Affair = dataHoursReportCopy.Affair;
        },
        dialogSendMailFun(){
            this.dialogSendMail = true;
            this.dataHoursReport.BeginDate = this.filter.HrtBeginDate;
            this.dataHoursReport.EndDate = this.filter.HrtEndDate;
        },
        returnDialog(){
            this.dialogPreviewMail = false;
            this.dialogSendMail = true;
        }
    },
    watch: {
        'dataHoursReport.BeginDate'(){
            this.filter.HrtBeginDate = this.dataHoursReport.BeginDate;
        },
        'dataHoursReport.EndDate'(){
            this.filter.HrtEndDate = this.dataHoursReport.EndDate;
        }
    }
}
</script>

<style>
        body {
            font-family: Arial, sans-serif;
            color: #202124;
            margin: 0;
            padding: 0;
            line-height: 1.5;
        }
        
        .email-container {
            max-width: 800px;
            margin: 0 auto;
            padding: 16px;
        }
        
        .email-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 16px;
            border-bottom: 1px solid #e0e0e0;
        }
        
        .email-title {
            font-size: 20px;
            color: #202124;
            font-weight: 400;
        }
        
        .email-label {
            background-color: #f1f3f4;
            color: #5f6368;
            padding: 0px 4px;
            border-radius: 4px;
            font-size: 11px;
            display: inline-flex;
            align-items: center;
        }
        
        .close-icon {
            margin-left: 4px;
            cursor: pointer;
            font-size: 16px;
            color: #5f6368;
            transform: translateY(-1px);
        }
        
        .email-actions {
            display: flex;
            justify-content: flex-end;
            gap: 12px;
        }
        
        .sender-info {
            display: flex;
            align-items: center;
            padding: 16px 0;
        }
        
        .profile-image {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: start;
            font-weight: bold;
            margin-right: 12px;
            border: 0.5px solid #c0c1c5;
        }
        
        .sender-details {
            flex-grow: 1;
        }
        
        .sender-email {
            font-weight: bold;
            color: #202124;
            font-size: 12px;
        }
        
        .recipient {
            color: #5f6368;
            font-size: 12px;
            display: flex;
            align-items: center;
        }
        
        .email-timestamp {
            color: #5f6368;
            font-size: 11px;
            display: flex;
            align-items: center;
            gap: 5px;
            margin-right: 5px;
        }
        
        .email-options {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 10px;
        }
        
        .email-content {
            padding: 10px 0;
            font-size: 12px;
        }
        
        .email-body {
            margin-bottom: 24px;
        }
        
        .email-divider {
            height: 1px;
            background-color: #e0e0e0;
            margin: 16px 0;
        }

        .attachment-section-container {
            display: flex;
            justify-content: space-between;
        }
        
        .attachment-section {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
        }
        
        .attachment-label {
            font-weight: bold;
            margin-right: 8px;
        }
        
        .attachment-info {
            color: #5f6368;
            display: flex;
            align-items: center;
            gap: 5px;
        }
        
        .info-icon {
            margin-left: 4px;
            color: #5f6368;
        }
        
        .attachment-container {
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            max-width: 160px;
        }
        
        .attachment-preview {
            width: 100%;
            height: 60px;
            overflow: hidden;
        }
        
        .attachment-preview img {
            width: 100%;
            object-fit: cover;
        }
        
        .attachment-details {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 11px;
            background-color: #F5F5F5;
            padding: 4px 8px;
        }
        
        .corner-triangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 20px 20px;
            border-color: transparent transparent #4caf50 transparent;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    </style>